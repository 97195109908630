.code-box {
    font-size: 12px;
    background-color: #f5f5f5;
    width: 50%;
    min-height: 50vh
}

/*CodeBox For Mobile*/
@media only screen and (max-width: 600px) {
    .code-box {
        width: 100%;
    }
}

.shadow-text {
    color: darkgray;
    font-size: 10px;
    display: inline-block;
}

/* The Modal (background) */
.upload-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

/* Modal Content */
.upload-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
.upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.upload-form input {
    width: 100%;
    height: 50px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    outline: none;
}

.upload-form label {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 50px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    outline: none;
    cursor: pointer;
    color: black;
}
.tutorial-box{
    background-color: #bdbdbd;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.tutorial-box h3{
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-family: sans-serif;
    font-weight: 500;
}

.tutorial-box p{
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 300;
    font-family: sans-serif;
    text-align: left;
}